<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="7"
          />
          <b-col
            cols="12"
            md="3"
            class="mb-1 mb-md-0"
          >
            <b-form-input
              v-model="query.filterStr"
              placeholder="검색어 (이름, 휴대전화번호)"
              maxlength="30"
              @keyup.enter="refetchData()"
            />
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="btn-search"
              @click.prevent="refetchData()"
            >
              조회
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <h5 class="mb-0 ml-50">
              비 고객 수 [{{ totalRecords }}] 명
            </h5>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              v-if="internal"
              variant="outline-dark"
              class="btn-search"
              @click.prevent="excelDownload()"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span>다운로드</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refMemberTable"
        primary-key="userHash"
        :items="fetchMembers"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDesc"
        responsive
        sticky-header
        selectable
        select-mode="single"
        class="position-relative"
        @row-selected="onRowSelectedMember"
      >
        <template #cell(fullName)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>
        <template #cell(phoneNumber)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>
        <template #cell(rewardAmount)="data">
          {{ data.value | $numberFormatter }}
        </template>
      </b-table>
      <b-pagination
        v-model="currPage"
        :total-rows="totalRecords"
        limit="5"
        per-page="5"
        align="center"
      />
    </b-card>

    <b-card no-body>
      <b-tabs
        pills
        class="m-2"
      >
        <b-tab>
          <template #title>
            <feather-icon
              icon="Share2Icon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">참여 내역 조회</span>
          </template>
          <non-member-act-history :user-hash="userHash" />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import {
  ref, onMounted, watch, getCurrentInstance,
} from '@vue/composition-api'
import { BTab, BTabs } from 'bootstrap-vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { saveAs } from "file-saver"
import NonMemberActHistory from './NonMemberActHistory.vue'

export default {
  components: {
    BTab,
    BTabs,
    NonMemberActHistory,
  },
  setup() {
    onMounted(() => {
    })

    const toast = useToast()

    const internal = getCurrentInstance().proxy.$internal

    // Query Data
    const query = ref({
      filterStr: null,
    })
    // Query Data End.

    // Main Table Config
    const userHash = ref(null)
    const refMemberTable = ref(null)
    const sortBy = ref(null)
    const isSortDesc = ref(true)
    const currPage = ref(1)
    const totalRecords = ref(0)
    const tableColumns = [
      {
        key: "userName", label: "이름", sortable: true, thStyle: { width: '12%' },
      },
      {
        key: "phoneNumber", label: "휴대전화번호", sortable: true, thStyle: { width: '12%' },
      },
      { key: "workAddress", label: "지역", sortable: true },
      {
        key: "medicalDeptName", label: "진료과", sortable: true, thStyle: { width: '12%' },
      },
      {
        key: "surveyJoinCnt", label: "설문조사참여건수", sortable: true, thStyle: { width: '12%' },
      },
      {
        key: "eventJoinCnt", label: "이벤트보상지급건수", sortable: true, thStyle: { width: '12%' },
      },
      {
        key: "rewardAmount", label: "교환금액", sortable: true, thStyle: { width: '12%' },
      },
    ]

    watch([currPage], () => {
      refMemberTable.value.refresh()
    })

    const refetchData = () => {
      refMemberTable.value.refresh()
    }

    const onRowSelectedMember = items => {
      const item = items[0]

      if (item && item.userHash) {
        userHash.value = item.userHash
      }
    }
    // Main Table Config End.

    const fetchMembers = (ctx, callback) => {
      axios
        .post("/fa/non-user/list", {
          search: query.value,
          sort: {
            predicate: sortBy.value,
            reverse: isSortDesc.value,
          },
          pagination: {
            number: currPage.value,
            count: 5,
          },
        })
        .then(rs => {
          const { items, totalRecord } = rs.data

          callback(items)

          totalRecords.value = totalRecord

          userHash.value = null
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: "데이터 조회에 실패하였습니다.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    }

    const excelDownload = () => {
      axios.post("/fa/non-user/excel", {
        search: null,
        sort: null,
      }, {
        responseType: "blob",
      })
        .then(rs => {
          const mimeType = {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }

          const blob = new Blob([rs.data], mimeType)

          saveAs(blob, "비고객관리_리스트.xlsx")
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: "데이터 조회에 실패하였습니다.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    }

    return {
      query,
      tableColumns,
      refMemberTable,
      sortBy,
      isSortDesc,
      userHash,
      refetchData,
      currPage,
      totalRecords,
      onRowSelectedMember,
      fetchMembers,
      excelDownload,
      internal,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
