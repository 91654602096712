<!-- 고객 참여 내역 조회 -->
<template>
  <div class="mt-2">
    <b-row>
      <b-col
        cols="12"
        md="8"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <b-form-radio-group
          v-model="query.historyType"
        >
          <b-form-radio :value="null">
            전체
          </b-form-radio>
          <b-form-radio :value="1">
            설문조사 참여 내역
          </b-form-radio>
          <b-form-radio :value="2">
            이벤트 참여 내역
          </b-form-radio>
        </b-form-radio-group>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-end"
      >
        <b-form-datepicker
          v-model="createDateFr"
          class="el-def"
          placeholder="기간(Fr)"
        />
        <label class="ml-50 mr-50">~</label>
        <b-form-datepicker
          v-model="createDateTo"
          class="el-def mr-1"
          placeholder="기간(To)"
        />
        <b-button
          variant="primary"
          class="btn-search w-50 mr-1"
          @click.prevent="searchActHistory()"
        >
          조회
        </b-button>
        <b-button
          variant="outline-dark"
          class="w-50 mr-1"
          @click.prevent="setQueryDate(3)"
        >
          3개월
        </b-button>
        <b-button
          variant="outline-dark"
          class="w-50"
          @click.prevent="setQueryDate(6)"
        >
          6개월
        </b-button>
      </b-col>
    </b-row>
    <b-card
      no-body
      class="mt-2"
    >
      <b-table
        primary-key="id"
        :items="actHistories"
        :fields="tableColumns"
        responsive
        sticky-header
        class="position-relative"
      >
        <template #cell(createDate)="data">
          <span class="text-nowrap">
            {{ data.value | $dateFormatter('YYYY-MM-DD HH:mm') }}
          </span>
        </template>
        <template #cell(title)="data">
          <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
            {{ data.value }}
          </div>
        </template>
        <template #cell(rewardAmount)="data">
          {{ data.value | $numberFormatter }}
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import dayjs from 'dayjs'
import { ref, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
  },
  props: {
    userHash: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const query = ref({
      userHash: null,
      historyType: null,
      searchDateFr: dayjs().add(-3, 'month').format('YYYY-MM-DD'),
      searchDateTo: dayjs().format('YYYY-MM-DD'),
    })

    const createDateFr = ref(dayjs().add(-3, 'month').format('YYYY-MM-DD'))
    const createDateTo = ref(dayjs().format('YYYY-MM-DD'))

    watch(() => props.userHash, newVal => {
      query.value.userHash = newVal
    })

    const toast = useToast()

    const actHistories = ref([])

    const fetchActHistories = () => {
      if (query.value.userHash) {
        axios.post('/fa/non-user/act-hists', {
          userHash: query.value.userHash,
          historyType: query.value.historyType,
          searchDateFr: query.value.searchDateFr === '' ? null : query.value.searchDateFr,
          searchDateTo: query.value.searchDateTo === '' ? null : query.value.searchDateTo,
        })
          .then(rs => {
            actHistories.value = rs.data
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: '데이터 조회에 실패하였습니다.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    }

    watch(query.value, () => {
      fetchActHistories()
    })

    const setQueryDate = term => {
      // query.value.createDateFr = dayjs().add(-term, 'month').format('YYYY-MM-DD')
      // query.value.createDateTo = dayjs().format('YYYY-MM-DD')
      createDateFr.value = dayjs().add(-term, 'month').format('YYYY-MM-DD')
      createDateTo.value = dayjs().format('YYYY-MM-DD')
    }

    const searchActHistory = () => {
      query.value.searchDateFr = createDateFr.value
      query.value.searchDateTo = createDateTo.value
    }
    // Query Data End.

    // Main Table Config
    const tableColumns = [
      {
        key: 'createDate', label: '참여 일시', sortable: true, thStyle: { width: '12%' },
      },
      {
        key: 'title', label: '참여 구분', sortable: true,
      },
      {
        key: 'reward', label: '보상', sortable: true, thStyle: { width: '20%' },
      },
      { key: 'rewardAmount', label: '금액', thStyle: { width: '10%' } },
      { key: 'userName', label: '이름', thStyle: { width: '10%' } },
      { key: 'phoneNumber', label: '휴대전화번호', thStyle: { width: '12%' } },
    ]
    // Main Table Config End.

    return {
      query,
      actHistories,
      setQueryDate,
      tableColumns,
      fetchActHistories,
      createDateFr,
      createDateTo,
      searchActHistory,
    }
  },
}
</script>
